import React from "react";

import Checkbox from "components/Global/CheckBox/CheckBox";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  toggleRow: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: "0.5rem",
    alignItems: "center",
    padding: "0.5rem 0",
  },
};

const checkboxStyles = {
  styledCheckBox: {
    height: "1rem",
    width: "1rem",
    background: colours.white,
    border: `1px solid ${colours.borderGrey}`,
  },
  styledCheckBoxChecked: {
    background: colours.checkboxSelected,
    color: colours.white,
    height: "1rem",
    width: "1rem",
    border: `1px solid ${colours.checkboxSelected}`,
  },
  icon: {
    color: colours.white,
    height: "0.75rem",
    width: "0.75rem",
  },
  checkBoxContainer: {
    marginRight: "0.5rem",
  },
  labelActive: {
    color: colours.selectedBlue,
  },
};

export default function ColumnManagementToggleItem(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { column } = props;

  if (!column.getCanHide() || !column) {
    return null;
  }

  const label = column.columnDef.header;
  const showing = column.getIsVisible();

  const handleToggle = () => {
    sendGAEvent({
      action: "toggleHideOrShowButtonClicked",
      selectedColumn: label,
      showing: !showing,
    });
    column.toggleVisibility();
  };

  return (
    <div className={css(styles.toggleRow)}>
      <Checkbox
        handleCheckboxChange={handleToggle}
        checked={showing}
        styles={checkboxStyles}
        label={label}
        id={label}
      />
    </div>
  );
}
